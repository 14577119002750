<template>
    <LargeColumnWithSidebar>
        <div class="whiteBox whiteBox--padding">
            <div class="top">
                <h2>Sjukanmälan</h2>
            </div>

            <div class="textHolder">
                <Content>
                    <p>Du sjukanmäler dig till tel: <a href="tel:051586953">0515-869 53</a>, ring före kl. 10.00.</p>

                    <p>Om du går på IM och inte har fullständigt personnummer gör du sjukanmälan på <a href="mailto:sjukanmalanIM@ronneby.se">sjukanmalanIM@ronneby.se</a> eller ring <a href="tel:0457618369">0457-61 83 69</a>.</p>

                    <p>Går du på IM och har fullständigt personnummer görs sjukanmälan till 0515-869 53.</p>

                    <p>Sjukanmälan kan bara göras för en hel dag i taget. Ny anmälan måste göras varje dag.</p>

                    <p>Om du ringer efter kl. 12.00 kommer frånvaro att registreras för nästkommande dag.</p>

                    <p>Om du blir sjuk under dagen ska du kontakta din mentor (det går bra att maila) för att frånvaron ska registreras för samma dag.</p>

                    <p>När det gäller planerad frånvaro tar du kontakt med din mentor i god tid.</p>

                    <p>Vid all frånvaro skickas ett sms ut till vårdnadshavaren med information om anledning för frånvaro som t.ex. anmäld eller oanmäld och tid för frånvaro.</p>

                    <p><b>Krya på dig!</b></p>
                </Content>
            </div>
        </div>

        <template v-slot:sidebar>
            <Reminders offset-top />
        </template>
    </LargeColumnWithSidebar>
</template>

<script>
import LargeColumnWithSidebar from '@/components/layout/LargeColumnWithSidebar';
import Content from '@/components/ui/Content';
import Reminders from '@/components/reminders/Reminders';

export default {
    components: {
        LargeColumnWithSidebar,
        Content,
        Reminders
    }
};
</script>

<style lang="postcss" scoped>
.top {
    margin-bottom: 3rem;
    @media (--laptop) {
        margin-bottom: 2.5rem;
    }
    @media (--mobile) {
        margin-bottom: 2rem;
    }
}

.largeColumnWithSidebar {
    padding-top: 4.7rem;
    @media (--mobile) {
        padding-top: 0;
    }
}

.textHolder {
    max-width: 700px;
}
</style>
